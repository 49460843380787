import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout";
import Waves_ from "../img/video.webm";
import Waves from "../img/video.mp4";
import volumeTesting from "../img/volume-testing.jpg";
import performanceTesting from "../img/performance-testing.jpg";
import stressTesting from "../img/stress-testing.jpg";
import loadTesting from "../img/load-testing.jpg";
import enduranceTesting from "../img/endurance-testing.jpg";
import spikeTesting from "../img/spike-testing.jpeg";
import scalability from "../img/scalability.jpeg";
import bottlenecks from "../img/perf-1.jpg";
import optimize from "../img/optimize.jpg";
import scalability_1 from "../img/scalability_1.jpg";
import experience from "../img/experience.jpg";
import riskImg from "../img/risk.jpg";
import ReactGA from "react-ga";
ReactGA.initialize("G-SGBEF28TMQ");

const IndexPage = (props) => {
  const [selectedItem, setItem] = useState(1);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <Layout>
      <div>
        <a
          href="#section-contact-us"
          onClick={() => {
            ReactGA.event({
              category: "Click",
              action: "Let's connect",
            });
          }}
          className="btn-floating"
        >
          Let's Connect
        </a>
      </div>
      <header className="header" id="about">
        <div className="header__logo-box">
          <Img
            fixed={props.data.top_logo.childImageSharp.fixed}
            alt="alt logo"
            className="header__logo"
          />
          <div className="header__tagLine">
            Complete performance engineering
            <br />
            and testing solutions & services
          </div>
        </div>
        <div className="header__text-box">
          <h1 className="heading-primary">
            <span className="heading-primary--sub">
              We help in identifying your system performance bottlenecks and
              assist you to achieve performance goals
            </span>
          </h1>
          <p className="link">
            <a
              href="#performance-testing"
              onClick={() => {
                ReactGA.event({
                  category: "Click",
                  action: "Performance Testing",
                });
              }}
            >
              Performance Testing
            </a>
            <a
              href="#continuous-testing"
              onClick={() => {
                ReactGA.event({
                  category: "Click",
                  action: "Continuous profiling",
                });
              }}
            >
              Continuous profiling
            </a>
            <a
              href="#gc-log-analyzer"
              onClick={() => {
                ReactGA.event({
                  category: "Click",
                  action: "GC Log Analyzer",
                });
              }}
            >
              GC Log Analyzer
            </a>
            <a
              href="#deep-profiling-solutions"
              onClick={() => {
                ReactGA.event({
                  category: "Click",
                  action: "Deep profiling solutions",
                });
              }}
            >
              Deep profiling solutions
            </a>
          </p>
        </div>
      </header>
      <main>
        <section className="section-tours">
          <div className="u-center-text u-margin-bottom-small">
            <h2 className="heading-secondary" id="section-services">
              Services We Offer
            </h2>
          </div>
          <div className="card-grid">
            <div className="card-grid__item" id="performance-testing">
              <div className="card">
                <div className="card__picture card__picture--3">&nbsp;</div>
                <h4 className="card__heading">
                  <span className="card__heading-span card__heading-span--3">
                    Performance Testing
                  </span>
                </h4>
                <p className="card__details">
                  Ensuring the scalability of infrastructure resources of the
                  cloud translates into application scalability and a smooth
                  user experience.
                  <br />
                  Aims to measure the performance, responsiveness, stability,
                  and scalability of an application under specific workloads.
                  <br />
                  The goal of performance testing is to identify and eliminate
                  bottlenecks and optimize the application's performance to meet
                  the expected user load.
                </p>
              </div>
            </div>
            <div className="card-grid__item" id="continuous-testing">
              <div className="card">
                <div className="card__picture card__picture--1">&nbsp;</div>
                <h4 className="card__heading">
                  <span className="card__heading-span card__heading-span--1">
                    Continuous profiling
                  </span>
                </h4>
                <p className="card__details">
                  <b>Reduced time to market</b>: By identifying performance
                  issues early in the development cycle, continuous profiling
                  can help to reduce the time it takes to get applications to
                  market.
                  <br />
                  <b>Real-time performance monitoring</b>: Continuous profiling
                  provides real-time insight into the performance of the
                  application, allowing issues to be identified and addressed as
                  they occur.
                  <br />
                  <b>Low overhead</b>: Continuous profiling typically has a low
                  overhead, as it only samples the application's execution at
                  regular intervals, rather than instrumenting every method
                  call.
                </p>
              </div>
            </div>
            <div className="card-grid__item" id="gc-log-analyzer">
              <div className="card">
                <div className="card__picture card__picture--2">&nbsp;</div>
                <h4 className="card__heading">
                  <span className="card__heading-span card__heading-span--2">
                    GC Log Analyzer
                  </span>
                </h4>
                <div className="card__details">
                  Innoura GC Log Analyzer can help identify patterns and trends
                  in the GC logs that may be indicative of performance issues.
                  <br />
                  Some of the key features includes:
                  <br />
                  <b>Visualization of GC events</b>: visualize GC events, such
                  as Minor GC, Full GC, and Concurrent GC.
                  <br />
                  <b>Memory usage analysis</b>: identify potential memory leaks
                  or other issues with memory usage.
                  <br />
                  <b>Pause analysis</b>: identify potential causes of these
                  pauses, large object allocations or inefficient garbage
                  collection algorithms.
                  <br />
                  <b>Recommendations for optimization</b>: optimizing report on
                  adjusting memory allocation or garbage collection settings.
                </div>
              </div>
            </div>
            <div className="card-grid__item" id="deep-profiling-solutions">
              <div className="card">
                <div className="card__picture card__picture--4">&nbsp;</div>
                <h4 className="card__heading">
                  <span className="card__heading-span card__heading-span--4">
                    Deep profiling solutions
                  </span>
                </h4>
                <p className="card__details">
                  <b>Memory profiling</b>: Memory leaks, excessive memory usage,
                  and other memory-related issues.
                  <br />
                  <b>Thread profiling</b>: Deadlocks, race conditions, and
                  excessive thread usage.
                  <br />
                  <b>Network profiling</b>: Slow network communication,
                  excessive network usage.
                  <br />
                  <b>Database profiling</b>: Slow database queries, excessive
                  database usage, and database-related errors.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="section-about">
          <div className="u-center-text u-margin-y-small">
            <h2 className="heading-secondary">why Performance Testing</h2>
          </div>
          <div className="container">
            <p className="paragraph">
              Performance testing is a type of software testing that measures
              the speed, scalability, stability, and responsiveness of an
              application or system under a particular workload. The purpose of
              performance testing is to ensure that the system performs well and
              meets the required performance criteria under expected and
              unexpected conditions.
            </p>
            <p className="paragraph">
              Performance testing is important for several reasons:
            </p>
          </div>
          <div className="row u-margin-bottom-medium">
            <div className="col-1-of-2">
              <div className="accordion">
                <div
                  className={`accordion-item ${
                    selectedItem === 1 ? "expand" : ""
                  }`}
                  onMouseOver={() => setItem(1)}
                >
                  <h5 className="accordion-item__title">
                    To identify performance bottlenecks
                    <span className="accordion-item__icon" />
                  </h5>
                  <p className="accordion-item__content">
                    Performance testing helps to identify any performance
                    bottlenecks or issues that may impact the system's
                    performance.
                  </p>
                </div>
                <div
                  className={`accordion-item ${
                    selectedItem === 2 ? "expand" : ""
                  }`}
                  onMouseOver={() => setItem(2)}
                >
                  <h5 className="accordion-item__title">
                    To optimize the system's performance
                    <span className="accordion-item__icon" />
                  </h5>
                  <p className="accordion-item__content">
                    Once the bottlenecks are identified, performance testing can
                    help to optimize the system's performance by addressing
                    these issues.
                  </p>
                </div>
                <div
                  className={`accordion-item ${
                    selectedItem === 3 ? "expand" : ""
                  }`}
                  onMouseOver={() => setItem(3)}
                >
                  <h5 className="accordion-item__title">
                    To validate scalability
                    <span className="accordion-item__icon" />
                  </h5>
                  <p className="accordion-item__content">
                    Performance testing helps to validate the system's
                    scalability by determining how well it performs under
                    different workload levels.
                  </p>
                </div>
                <div
                  className={`accordion-item ${
                    selectedItem === 4 ? "expand" : ""
                  }`}
                  onMouseOver={() => setItem(4)}
                >
                  <h5 className="accordion-item__title">
                    To enhance user experience
                    <span className="accordion-item__icon" />
                  </h5>
                  <p className="accordion-item__content">
                    Performance testing helps to ensure that the system is
                    responsive and provides a good user experience.
                  </p>
                </div>
                <div
                  className={`accordion-item ${
                    selectedItem === 5 ? "expand" : ""
                  }`}
                  onMouseOver={() => setItem(5)}
                >
                  <h5 className="accordion-item__title">
                    To reduce risk <span className="accordion-item__icon" />
                  </h5>
                  <p className="accordion-item__content">
                    Performance testing helps to reduce the risk of downtime,
                    lost revenue, and damage to the system's reputation by
                    identifying and addressing performance issues before they
                    occur in a production environment.
                  </p>
                </div>
              </div>
            </div>
            <div className={`col-1-of-2 alignCenter imgHolder_${selectedItem}`}>
              <img src={bottlenecks} className="img_1" />
              <img src={optimize} className="img_2" />
              <img src={scalability_1} className="img_3" />
              <img src={experience} className="img_4" />
              <img src={riskImg} className="img_5" />
            </div>
          </div>
          <div className="container">
            <p className="paragraph" style={{ fontSize: "1.8rem" }}>
              Overall, performance testing is an important aspect of software
              development and maintenance, as it ensures that the system
              performs well and meets the required performance criteria.
            </p>
          </div>
        </section>
        <section className="section-features" id="features">
          <div className="u-center-text">
            <h2 className="heading-secondary heading-secondary__white">
              Our Performance Testing Services
            </h2>
          </div>
          <div className="row">
            <div className="col-1-of-4">
              <div className="feature-box">
                <img src={loadTesting} />
                <p className="feature-box__text">
                  <span className="highlight">Load testing</span> involves
                  testing the system's performance under a heavy workload to
                  determine its capacity and identify any performance
                  bottlenecks.
                </p>
              </div>
            </div>
            <div className="col-1-of-4">
              <div className="feature-box">
                <img src={stressTesting} />
                <p className="feature-box__text">
                  <span className="highlight">Stress testing</span> involves
                  testing the system's performance beyond its capacity to
                  determine its breaking point and identify any potential
                  failures.
                </p>
              </div>
            </div>
            <div className="col-1-of-4">
              <div className="feature-box">
                <img src={volumeTesting} />
                <p className="feature-box__text">
                  <span className="highlight">Volume testing</span> involves
                  testing the system's performance with a large volume of data
                  to determine its capacity and identify any performance issues
                  related to data handling.
                </p>
              </div>
            </div>
            <div className="col-1-of-4">
              <div className="feature-box">
                <img src={enduranceTesting} />
                <p className="feature-box__text">
                  <span className="highlight">Endurance testing</span> involves
                  testing the system's performance over an extended period to
                  determine its stability and identify any performance
                  degradation over time.
                </p>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{
              justifyContent: "center",
            }}
          >
            <div className="col-1-of-4">
              <div className="feature-box">
                <img src={spikeTesting} />
                <p className="feature-box__text">
                  <span className="highlight">Spike testing</span> involves
                  testing the system's performance under a sudden and extreme
                  workload to determine its ability to handle unexpected spikes
                  in user traffic.
                </p>
              </div>
            </div>
            <div className="col-1-of-4">
              <div className="feature-box">
                <img src={scalability} />
                <p className="feature-box__text">
                  <span className="highlight">Scalability</span> testing
                  involves testing the system's performance as the workload
                  increases to determine its ability to scale up and handle
                  increasing user traffic.
                </p>
              </div>
            </div>
            <div className="col-1-of-4">
              <div className="feature-box">
                <img src={performanceTesting} />
                <p className="feature-box__text">
                  <span className="highlight">
                    Component-level performance testing
                  </span>{" "}
                  involves testing the performance of individual components of
                  the system, such as databases or APIs, to identify any
                  performance issues at the component level.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <p className="paragraph white">
              Overall, the type of performance testing service required depends
              on the specific needs of the system and the performance criteria
              that need to be met.
            </p>
          </div>
        </section>

        <section className="section-stories" id="section-locations">
          <div className="bg-video">
            <video className="bg-video__content" autoPlay muted loop>
              <source src={Waves} type="video/mp4" />
              <source src={Waves_} type="video/webm" />
              Your browser is not supported!
            </video>
          </div>
          <div className="u-center-text u-margin-bottom-small">
            <h2 className="heading-secondary">Our Locations</h2>
          </div>
          <div className="row">
            <div className="story">
              <figure className="story__shape">
                <Img
                  fluid={props.data.singaporeFlag.childImageSharp.fluid}
                  alt="Singapore"
                  className="story__img"
                />

                <figcaption className="story__caption">Singapore</figcaption>
              </figure>
              <div className="story__text">
                <h3 className="heading-tertiary u-margin-bottom-small">
                  Singapore
                </h3>
                <p>
                  Innoura Technologies Pvt Ltd
                  <br />
                  9 Temsasek Boulevard, Suntec Tower 2,
                  <br />
                  #09-01 Singapore 038989, Contact: +65 9224 7494
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="story">
              <figure className="story__shape">
                <Img
                  fluid={props.data.indiaFlag.childImageSharp.fluid}
                  alt="India"
                  className="story__img"
                />

                <figcaption className="story__caption">Chennai</figcaption>
              </figure>
              <div className="story__text">
                <h3 className="heading-tertiary u-margin-bottom-small">
                  India
                </h3>
                <p>
                  Innoura Technologies Pvt Ltd
                  <br />
                  Tek Tower, No.11, Ground Floor, Rajiv Gandhi Salai (OMR),
                  <br />
                  Thoraipakkam, Chennai - 600097, Contact: +91 82487 70445
                </p>
              </div>
            </div>
          </div>
        </section>
        <section
          className="section-book"
          id="section-contact-us"
          style={{ zIndex: 10, position: "relative" }}
        >
          <div className="row">
            <div className="book">
              <div className="book__form">
                <form
                  action="https://formspree.io/f/xpzejlwq"
                  method="POST"
                  className="form"
                >
                  <h2 className="heading-secondary">Book your free demo</h2>
                  <div className="form__group">
                    <input
                      type="text"
                      className="form__input"
                      placeholder="Full name"
                      id="name"
                      name="name"
                      required
                    />
                  </div>
                  <div className="form__group">
                    <input
                      type="email"
                      className="form__input"
                      placeholder="Email address"
                      id="email"
                      name="email"
                      required
                    />
                  </div>
                  <div className="form__group">
                    <input
                      type="text"
                      className="form__input"
                      placeholder="Firm Name"
                      id="firm"
                      name="firm"
                      required
                    />
                  </div>
                  <div className="form__group">
                    <input
                      type="text"
                      className="form__input"
                      placeholder="Country"
                      id="country"
                      name="country"
                      required
                    />
                  </div>
                  <div className="form__group">
                    <input
                      type="text"
                      className="form__input"
                      placeholder="Phone"
                      id="phone"
                      name="phone"
                      required
                    />
                  </div>
                  <div className="form__group">
                    <textarea
                      className="form__input"
                      id="comments"
                      name="comments"
                      rows="5"
                      cols="40"
                      placeholder="Tell Us About Your Project"
                    />
                  </div>
                  <div className="form__group u-margin-bottom-medium">
                    <div className="form__radio-group">
                      {/* <input type="radio" class="form__radio-input" id="small" name="size">
                                        <label for="small" class="form__radio-label">
                                            <span class="form__radio-button"></span>
                                            Small tour group
                                        </label> */}
                    </div>
                    <div className="form__radio-group">
                      {/*  <input type="radio" class="form__radio-input" id="large" name="size">
                                        <label for="large" class="form__radio-label">
                                            <span class="form__radio-button"></span>
                                            Large tour group
                                        </label> */}
                    </div>
                  </div>
                  <div className="form__group">
                    <button
                      className="btn btn--green"
                      onClick={() => {
                        ReactGA.event({
                          category: "click",
                          action: "Book demo submit",
                        });
                      }}
                      type="submit"
                    >
                      Submit →
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>

      <div>
        <footer className="footer">
          <div className="footer__logo-box">
            <picture className="footer__logo">
              <source
                srcSet="img/page_main_logo.png 1x, img/page_main_logo.png 2x"
                media="(max-width: 37.5em)"
              />

              <Img
                fixed={props.data.logo.childImageSharp.fixed}
                alt="alt logo"
                className="header__logo"
              />
              <p className="footer__logo__tag">
                COMPLETE PERFORMANCE ENGINEERING AND
                <br />
                TESTING SOLUTIONS & SERVICES
              </p>
            </picture>
          </div>
          <div className="row">
            <div className="col-1-of-2">
              <div className="footer__navigation">
                <ul className="footer__list">
                  <li className="footer__item">
                    <a href="#about" className="footer__link">
                      Company
                    </a>
                  </li>
                  <li className="footer__item">
                    <a href="#section-contact-us" className="footer__link">
                      Contact us
                    </a>
                  </li>
                  <li className="footer__item">
                    <a href="#section-services" className="footer__link">
                      Services
                    </a>
                  </li>
                  {/* <li class="footer__item"><a href="#" class="footer__link">Privacy policy</a></li>
                        <li class="footer__item"><a href="#" class="footer__link">Terms</a></li> */}
                </ul>
              </div>
            </div>
            <div className="col-1-of-2 right-col">
              <p className="footer__copyright">
                Built by{" "}
                <a href="innoura.com" className="footer__link">
                  Innoura Technologies
                </a>
                <br />
                Copyright 2018 © by Innoura Technologies.
              </p>
            </div>
          </div>
        </footer>

        <div className="popup" id="popup-blockchain">
          <div className="popup__content">
            <div className="popup__left">
              <Img
                fluid={props.data.natEight.childImageSharp.fluid}
                alt="3"
                className="popup__img"
              />
              <Img
                fluid={props.data.natNine.childImageSharp.fluid}
                alt="3"
                className="popup__img"
              />
            </div>
            <div className="popup__right">
              <a href="#section-services" className="popup__close">
                ×
              </a>
              <h2 className="heading-secondary u-margin-bottom-small">
                Our Blockchain Solutions
              </h2>
              <h3 className="heading-tertiary u-margin-bottom-small">
                Building Fintech applications using Stellar blockchain platform.
              </h3>
              <p className="popup__text">
                Having a team of Stellar Blockchain development experts, we make
                you understand the potential of Stellar for your business
                perspective. API. With Horizon (Stellar API), we can help
                deliver an enterprise-grade product. Our hands-on experience in
                building software including mobile, payment systems and banking
                tools. Our team of developers has expertise in Java &amp;
                Solidity and has developed 100+ Smart Contracts. We build the
                fidelity user interface design, technical architecture, user
                stories and develop sprints and delivery milestones.
              </p>
              <a href="#section-contact-us" className="btn btn--green">
                Get in touch
              </a>
            </div>
          </div>
        </div>
        <div className="popup" id="popup-website">
          <div className="popup__content">
            <div className="popup__left">
              <Img
                fluid={props.data.natEight.childImageSharp.fluid}
                alt="3"
                className="popup__img"
              />
              <Img
                fluid={props.data.natNine.childImageSharp.fluid}
                alt="3"
                className="popup__img"
              />
            </div>
            <div className="popup__right">
              <a href="#section-services" className="popup__close">
                ×
              </a>
              <h2 className="heading-secondary u-margin-bottom-small">
                Our Website Development Solutions
              </h2>
              <h3 className="heading-tertiary u-margin-bottom-small">
                Website Design drives your application’s success
              </h3>
              <p className="popup__text">
                Create compelling experiences, drive user acquisition, engage
                with customers, and drive home your brand with Website Design.
                From strategy to implementation, we collaborate with you to
                design experiences that build successful Website and businesses.
              </p>
              <a href="#section-contact-us" className="btn btn--green">
                Get in touch
              </a>
            </div>
          </div>
        </div>
        <div className="popup" id="popup-mobile">
          <div className="popup__content">
            <div className="popup__left">
              <Img
                fluid={props.data.natEight.childImageSharp.fluid}
                alt="3"
                className="popup__img"
              />
              <Img
                fluid={props.data.natNine.childImageSharp.fluid}
                alt="3"
                className="popup__img"
              />
            </div>
            <div className="popup__right">
              <a href="#section-services" className="popup__close">
                ×
              </a>
              <h2 className="heading-secondary u-margin-bottom-small">
                Our Mobile App Development
              </h2>
              <h3 className="heading-tertiary u-margin-bottom-small">
                From idea to launch, in eight weeks
              </h3>
              <p className="popup__text<Footer ></Footer>">
                You’ve got the vision. Now you need an expert partner focused on
                delivering value to your business and users. We like to say that
                “mobile” refers to the user, not the device. With that in mind,
                we’ll help you with your first critical product decisions like
                technology, branding, design, and experience. And we’ll do it
                fast.
              </p>
              <a href="#section-contact-us" className="btn btn--green">
                Get in touch
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;
export const fluidWebpImage = graphql`
  fragment fluidWebpImage on File {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;
export const headerLogo = graphql`
  fragment headerLogo on File {
    childImageSharp {
      fixed(width: 180) {
        ...GatsbyImageSharpFixed_noBase64
      }
    }
  }
`;
export const footerImage = graphql`
  fragment footerImage on File {
    childImageSharp {
      fixed(width: 160) {
        ...GatsbyImageSharpFixed_noBase64
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    natOne: file(relativePath: { eq: "nat-1.jpg" }) {
      ...fluidImage
    }
    natTwo: file(relativePath: { eq: "nat-2.jpg" }) {
      ...fluidImage
    }
    natThree: file(relativePath: { eq: "nat-3.jpg" }) {
      ...fluidImage
    }
    natEight: file(relativePath: { eq: "nat-8.jpg" }) {
      ...fluidImage
    }
    natNine: file(relativePath: { eq: "nat-3.jpg" }) {
      ...fluidImage
    }
    top_logo: file(relativePath: { eq: "logo-white1.png" }) {
      ...headerLogo
    }
    logo: file(relativePath: { eq: "page_main_logo.png" }) {
      ...footerImage
    }
    singaporeFlag: file(
      relativePath: { eq: "1920px-Flag_of_the_President_of_Singapore.svg.png" }
    ) {
      ...fluidImage
    }
    indiaFlag: file(relativePath: { eq: "510px-Flag_of_India.svg.webp" }) {
      ...fluidWebpImage
    }
    system: file(relativePath: { eq: "system.jpg" }) {
      ...fluidImage
    }
  }
`;
